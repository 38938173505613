<template>
    <div class="category-list"> 
 
    </div>
  </template>
  
  <script> 
  
  export default {
    data() { 
    },  
  };
  </script>
  
   