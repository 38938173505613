import { createRouter, createWebHistory } from 'vue-router'; 
import NoahMain from './components/NoahMain.vue';
import ProductMain from './components/ProductMain.vue';
import Microbladings from './components/MicroBladings.vue';
import BrowPigments from './components/BrowPigments.vue';
import LipPigments from './components/LipPigments.vue';
import MicroDetail from './components/MicroDetail.vue';

import SatinAl from './components/SatinAl.vue';
import ShowRoom from './components/ShowRoom.vue';

import UcretsizEgitim from './components/education/UcretsizEgitim.vue';
import EducationDetail from './components/education/EducationDetail.vue';

import KendiniTestEt from './components/KendiniTestEt.vue';
import MasterClassEgitim from './components/MasterClassEgitim.vue';
import NoahMasterWorld from './components/NoahMasterWorld.vue';

import UluslararasiSertifikalar from './components/UluslararasiSertifikalar.vue';

import MobilUygulamalar from './components/MobilUygulamalar.vue';
import NoahDuyurular from './components/NoahDuyurular.vue'; 

const routes = [ 
  { path: '/', component: NoahMain, meta: { title: 'Noah PMU - Premium Pigments' } },
  { path: '/urunler', component: ProductMain, meta: { title: 'Noah PMU - Ürünler' } },
  { path: '/satinal', component: SatinAl, meta: { title: 'Noah PMU - Satın Al' } },
  { path: '/showroom', component: ShowRoom, meta: { title: 'Noah PMU - Showroom' } },

  { path: '/ucretsizegitim', component: UcretsizEgitim, meta: { title: 'Noah PMU - Ücretsiz Eğitim' } },
  {
    path: '/educations/:educationId',
    name: 'EducationDetail',
    component: EducationDetail,
    props: true,
    meta: { title: 'Noah PMU - Eğitim Detayı' },
  },

  { path: '/kendinitestet', component: KendiniTestEt, meta: { title: 'Noah PMU - Kendini Test Et' } },
  { path: '/masterclassegitim', component: MasterClassEgitim, meta: { title: 'Noah PMU - MasterClass Eğitim' } },
  { path: '/noahmasterworld', component: NoahMasterWorld, meta: { title: 'Noah PMU - Master World' } },
  { path: '/sertifikalar', component: UluslararasiSertifikalar, meta: { title: 'Noah PMU - Sertifikalar' } },
  { path: '/mobiluygulamalar', component: MobilUygulamalar, meta: { title: 'Noah PMU - Mobil Uygulamalar' } },
  { path: '/etkinlikler', component: NoahDuyurular, meta: { title: 'Noah PMU - Duyurular' } },
 
  { path: '/lippigments', component: LipPigments, meta: { title: 'Noah PMU - Lip Pigments' } },
  { path: '/browpigments', component: BrowPigments, meta: { title: 'Noah PMU - Brow Pigments' } },
  { path: '/microbladingpigments', component: Microbladings, meta: { title: 'Noah PMU - Microblading Pigments' } },
  
  { path: '/microdetail/:title', name: 'MicroDetail', component: MicroDetail, props: true, meta: { title: 'Noah PMU - Micro Detay' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) { 
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});
 
router.beforeEach((to, from, next) => {
  const defaultTitle = 'Noah PMU';
  document.title = to.meta.title || defaultTitle;
  next();
});

export default router;
