<template>
  <div class="noah-loader" v-if="isLoading">
    <div class="loader-container">
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 145 54"
        class="loader-svg"
      >
        <path
          class="cls-1"
          d="m143.84,52.76V.5s-7.23,0-7.23,0v26.46h-24.11V.5h-7.24v31.72L93.39.5h-1.74l-7.28,19.79C81.3,8.88,71.16.5,60.11.5c-7.34,0-14.78,4.21-19.65,10.71-.09.12-.18.23-.26.36-3.25,4.46-5.28,9.95-5.28,15.87,0,2.65.38,5.24,1.08,7.7L15.6.5h-7.45l-.1,52.07H0"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "NoahLoader",
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.noah-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-svg {
  width: 150px; 
  height: auto; 
}

.cls-1 {
  fill: none;
  stroke: #a8a8a7;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-dasharray: 500;
  stroke-dashoffset: 0;
  animation: draw 4s linear infinite reverse, color-change 3s linear infinite;
}

@keyframes draw {
  to {
    stroke-dashoffset: -1000;
  }
}

@keyframes color-change {
  0% {
    stroke: #a8a8a7;
  }
  50% {
    stroke: #f39c12;
  }
  100% {
    stroke: #27ae60;
  }
}
</style>
