<template>
    <div>
        <div class="place">
    
        </div>
        <div class="background">
          <div
            class="line"
            v-for="(product, index) in products"
            :key="index"
            :class="'line-' + (index + 1)"
          >
            <div class="product">
              <img :src="product.image" alt="Product Image" class="product-image" />
              <h2 class="product-title">{{ product.title }}</h2>
            </div>
          </div>
        </div>
    
    </div>
    </template>
      
      <script>
      export default {
        data() {
          return {
            products: [
              { title: "Product 1", image: "https://via.placeholder.com/150" },
              { title: "Product 2", image: "https://via.placeholder.com/150" },
              { title: "Product 3", image: "https://via.placeholder.com/150" },
              { title: "Product 4", image: "https://via.placeholder.com/150" }
            ]
          };
        }
      };
      </script>
      
      <style scoped> 
      
      .background {
        position: relative;
        width: 100%;
        height: 140vh; 
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .place { 
        height: 20vh; 
      }
      
      .line {
        position: relative;
        width: 110%;
        height: 20vh;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.7s ease;
        transform: rotate(-4deg);
        transform-origin: left top;
      }
      
      .line-1 {
        background-color: #00ffff;
      }
      
      .line-2 {
        background-color: #00ff00;
      }
      
      .line-3 {
        background-color: #0000ff;
      }
      
      .line-4 {
        background-color: #8b0000;
      }
      
      .line:hover {
        height: 40vh; 
      }
      
      .product {
        text-align: center;
        color: white;
        z-index: 2;
      }
      
      .product-image {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      
      .product-title {
        font-size: 1.5em;
        margin: 0.5em 0;
      }
      </style>
      