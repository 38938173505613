<template>
  <div class="main-div">
    <NoahLoader :isLoading="isLoading" />
    <div class="shader-slider" v-if="!isLoading">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(slide, index) in slides"
            :key="index"
            @click="updateSubCategory(index)"
          >
            <img
              v-if="getResponsiveImage(slide)"
              class="swiper-gl-image"
              :src="getResponsiveImage(slide)"
              :alt="slide.name || 'Alt Kategori'"
              style="opacity: 0;"
              @load="handleImageLoad"
            />
            <div class="swiper-slide-content">
              <div class="swiper-slide-title">{{ slide.name }}</div>
            </div>
          </div>
        </div>
        <div class="mainbutton-prev"> 
          <img src="@/assets/icon/products/previous.svg" alt="Previous" />
        </div>
        <div class="mainbutton-next"> 
          <img src="@/assets/icon/products/next.svg" alt="Next" />
        </div>
      </div>
    </div>
    <SubCategory v-if="selectedCategory && !isLoading" :selectedCategory="selectedCategory" />
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import axios from 'axios';
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import SwiperGL from './swiper/swiper-gl.esm.js';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import './swiper/swiper-gl.scss';
import SubCategory from './SubCategory.vue';
import NoahLoader from './NoahLoader.vue';

export default {
  name: 'ProductMain',
  components: {
    SubCategory,
    NoahLoader,
  },
  setup() {
    const swiperInstance = ref(null);
    const slides = ref([]);
    const selectedCategory = ref(null);
    const loadedImages = ref(0);
    const isLoading = ref(true);

    const fetchSubCategories = async () => {
      try {
        const response = await axios.get('/auth/sub-categories');
        slides.value = response.data.subCategories.map((subcategory) => ({
          id: subcategory.id,
          name: subcategory.name,
          mobil_image: subcategory.mobil_image,
          web_image: subcategory.web_image,
        }));
    
        if (slides.value.length > 0) {
          selectedCategory.value = slides.value[0];
        }
    
        isLoading.value = false; 
      } catch (error) {
        console.error('Alt kategoriler yüklenirken hata:', error); 
      }
    };
    

    const initSwiper = () => {
      if (!slides.value.length) return;
    
      swiperInstance.value = new Swiper('.swiper', {
        modules: [Navigation, Pagination, Autoplay, SwiperGL],
        effect: 'gl',
        loop: slides.value.length > 2,  
        navigation: {
          nextEl: '.mainbutton-next',
          prevEl: '.mainbutton-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: false,  
        gl: {
          shader: 'wave-x',
          displacementMap: '/src/components/swiper/gl/displacement/disp-big.jpg',
          shaderPerSlide: true,
        },
        on: {
          init() {
            if (this.gl && this.gl.loadTextures) {
              this.gl.loadTextures();
            }
          },
          slideChange() {
            if (!swiperInstance.value) return;
    
            const activeIndex = swiperInstance.value.realIndex || 0;
            if (slides.value[activeIndex]) {
              selectedCategory.value = slides.value[activeIndex];
            }
          },
        },
      });
    };  

    const updateSubCategory = (index) => {
      if (slides.value[index]) {
        selectedCategory.value = slides.value[index];
      }
    };

    const getResponsiveImage = (slide) => {
      if (!slide) return null;

      return window.innerWidth <= 768
        ? slide.mobil_image || '/images/placeholder.png'
        : slide.web_image || '/images/placeholder.png';
    };

    const handleImageLoad = () => {
      loadedImages.value += 1;
    
      if (loadedImages.value >= slides.value.length) {
        initSwiper(); 
      }
    }; 

    onMounted(() => {
      fetchSubCategories();

      window.addEventListener('resize', handleResize);
    });

    const handleResize = () => {
      if (swiperInstance.value) {
        swiperInstance.value.update();
      }
    };

    onUnmounted(() => {
      if (swiperInstance.value) {
        swiperInstance.value.destroy(true, true);
        swiperInstance.value = null;
      }
      window.removeEventListener('resize', handleResize);
    });

    return {
      slides,
      selectedCategory,
      isLoading,
      updateSubCategory,
      getResponsiveImage,
      handleImageLoad,
    };
  },
};
</script>

 
<style scoped>
.hidden {
  display: none;
}

.main-div{
  padding: 0;
}
.shader-slider {
  width: 100%;
  height: 78vh;
  padding: 0;
  margin:0;
  cursor: grab;
}
.swiper {
  position: relative;
  width: 100%;
  height: 78vh;
}
.swiper-wrapper {
  display: flex;
}
.swiper-slide {
  position: relative;
  width: 100%;
  height: 100%;
}
.swiper-gl-image {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0;
}
.swiper-slide-content {
  position: absolute;
  bottom: 50px;
  left: 20px;
  color: white;
  font-size: 1.3rem;
}
.swiper-slide-title {
  font-size: 2em;
  font-weight: 200;
}

.mainbutton-prev,
.mainbutton-next {
  position: absolute;
  top: 70%;
  transform: translateY(-50%);
  z-index: 10;
  cursor: pointer;
}

.mainbutton-prev {
  left: 10px;
}

.mainbutton-next {
  right: 10px;
}

.mainbutton-prev img,
.mainbutton-next img {
  height: 6em;
  opacity: 0.5; 
  animation: bounce 3s infinite;
}
.mainbutton-prev img:hover ,
.mainbutton-next img:hover {
  transform: scale(1.2);
}

@media (max-width: 728px) {
  .swiper-slide-content {
    background-color: rgba(0, 0, 0, 0); 
    padding: 10px;  
    border-radius: 5px;  
    width: 100%;
    left:0;
  }
  .swiper-slide-title {
  font-size: 0.9em;
  font-weight: 200;
  margin:1em 0;
}
} 

.mainbutton-prev img { 
  animation: bounce 3s infinite;
}
 
.mainbutton-next img { 
  animation: bounce2 3s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-10px);  
  }
}

@keyframes bounce2 {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);  
  }
}
</style> 