<template>
  <div>
    <h1>Microblading Product: {{ title }}</h1> 
  </div>
</template>

<script>
export default {
  props: ['title'],  
  created() { 
    console.log('Selected product:', this.title);
  }
};
</script>
