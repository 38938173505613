<template>
    <div>
      <div class="place"></div>
      <div class="placetext">Microblading</div>
      <div class="background">
        <div
          class="line"
          v-for="(product, index) in products"
          :key="index"
          :class="'line-' + (index + 1)"
          @mouseenter="playVideo(index)"
          @mouseleave="pauseVideo(index)"
        >
          <video
            :id="'video-' + index"
            class="background-video"
            :src="product.video"
            muted
            loop
          ></video>
          <div class="product">
            <img :src="product.image" alt="Product Image" class="product-image" />
            <button class="product-button" @click="goToProductDetail(product.title)">
              İncele
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  

<script>
export default {
  data() {
    return {
      products: [
        {
          title: "Golden",
          image: "/assets/images/golden.webp",
          video: "/assets/video/headg.mp4", 
        },
        {
          title: "Brown 1",
          image: "/assets/images/brown1.webp",
          video: "/assets/video/headb1.mp4", 
        },
        {
          title: "Brown 2",
          image: "/assets/images/brown2.webp",
          video: "/assets/video/headb2a.mp4", 
        },
        {
          title: "Brown 3",
          image: "/assets/images/brown3.webp",
          video: "/assets/video/head.mp4", 
        },
        {
          title: "Mustard",
          image: "/assets/images/mustard.webp",
          video: "/assets/video/head.mp4", 
        },
        {
          title: "Orange",
          image: "/assets/images/orange.webp",
          video: "/assets/video/heado.mp4", 
        },
        {
          title: "Black",
          image: "/assets/images/black.webp",
          video: "/assets/video/headb.mp4", 
        }, 
      ],
    };
  },
  methods: {
    playVideo(index) {
      const video = document.getElementById(`video-${index}`);
      if (video && video.paused) {
        video.play();
      }
    },
    pauseVideo(index) {
      const video = document.getElementById(`video-${index}`);
      if (video && !video.paused) {
        video.pause();
      }
    },
    goToProductDetail(title) {
      this.$router.push({ name: 'MicroDetail', params: { title } });
    },
  }, 
};
</script>


  
<style scoped>
.background {
  position: relative;
  width: 100%;
  height: 225vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.place {
  height: 25vh;
}
.placetext { 
  font-size: 3rem;
  font-weight:200;
  font-family: "Comfortaa", sans-serif;
}
.line {
  position: relative;
  width: 110%;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.7s ease;
  transform: rotate(-4deg);
  transform-origin: left top;
}

.line:hover {
  height: 45vh;
}

.line-1 {
  background-color: #0a0a0a;
}

.line-2 {
  background-color: #0a0a0a;
}

.line-3 {
  background-color: #0a0a0a;
}

.line-4 {
  background-color: #0a0a0a;
}
.line-5 {
  background-color: #0a0a0a;
}

.line-6 {
  background-color: #0a0a0a;
}

.line-7 {
  background-color: #0a0a0a;
}
.product {
  text-align: center; 
  z-index: 2;
  position: relative;  
}

.product-button {
  opacity: 0;
  position: absolute;
  top: 70%;
  right: -250px; 
  transform: translateY(-50%) rotate(4deg); 
  background-color: rgba(2, 48, 50, 0.5);
  color: rgba(255, 255, 255, 0.5);
  padding: 0.5em 2em;
  border: none;
  cursor: pointer;
  transition: all 0.7s ease;
  font-weight: 300;
  font-size: 1rem;
  border-radius: 5px;
}

.product-button:hover { 
  background-color: rgba(2, 48, 50, 1);
  color: rgba(255, 255, 255, 1); 
}

.line:hover .product-button {
  right: 20%;
  opacity: 1;
  transform: translateY(-50%) rotate(4deg);
}


.product-image {
  max-width: 30rem; 
  object-fit: cover;
}

.product-title {
  font-size: 1.5em;
  margin: 0.5em 0;
  margin-top: -5rem;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.1;
  transition: opacity 0.7s ease;
}

.line:hover .background-video {
  opacity: 0.5;
}

@media (max-width: 768px) {
    .product-image {
      max-width: 16rem; 
      object-fit: cover;
    }
  }
</style>
