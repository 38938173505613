<template>
  <div id="app">
    <MainHeader />
    <SideMenu />
    <MainFooter />
    <MaintenanceModal  />
  </div>
</template>

<script>
import MainHeader from './components/MainHeader.vue';
import MainFooter from './components/MainFooter.vue';
import SideMenu from './components/SideMenu.vue';
import MaintenanceModal from './components/MaintenanceModal.vue';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    MainHeader,
    MainFooter,
    SideMenu,
    MaintenanceModal,
  },
  data() {
    return {
      isMaintenanceMode: true,
    };
  },
 created() {
   this.checkMaintenanceMode();
 },
 methods: {
   async checkMaintenanceMode() {
     try {
       const response = await axios.get('/auth/web-status/1');
       this.isMaintenanceMode = !response.data?.is_active;
     } catch (error) {
       console.error('Error checking maintenance mode:', error); 
       this.isMaintenanceMode = true;
     }
   },
 },
};
</script>