<template>
  <div :class="{ 'menu': true, 'open': isOpen }" @click="toggleMenu" @touchstart="startTouch" @touchmove="moveTouch">
    <div class="menu-content"> 
      <p><img  src="../assets/icon/side-menu/shop-icon.svg" /> </p>
    </div>
    <div class="menu-content bottom-icons">
      <p><img  src="../assets/icon/side-menu/docs-icon.svg" /> </p>
      <p><img  src="../assets/icon/side-menu/download-icon.svg" /> </p>
      <p><img  src="../assets/icon/side-menu/whatsapp-icon.svg" /> </p>
      <p><img  src="../assets/icon/side-menu/instagram-icon.svg" /> </p>
    </div>
    <div class="menu-tab"> 
      <img :class="{ 'rotated': isOpen }" src="../assets/icon/side-menu-icon.svg" class="side-menu-icon" /> 
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideMenu',
  data() {
    return {
      isOpen: false,
      startX: 0
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    startTouch(e) {
      this.startX = e.touches[0].clientX;
    },
    moveTouch(e) {
      if (e.touches[0].clientX - this.startX > 50) { 
        this.isOpen = true;
      } else if (this.startX - e.touches[0].clientX > 50) { 
        this.isOpen = false;
      }
    }
  }
}
</script> 
