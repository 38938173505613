<template>
  <div class="education-detail">
    <p class="education-name">{{ education.name }}</p>
    <p class="konular">"Konular"</p>

    <div class="serial-no-list">
      <div
        v-for="(data, index) in educationData"
        :key="data.serial_no"
        class="serial-no-box"
        :class="{ selected: index === currentIndex }"
        @click="selectTopic(index)"
      >
        {{ data.serial_no }}
      </div>
    </div>

    <div
      class="swipe-container"
      ref="swipeContainer"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
    >
      <div
        class="topics-wrapper"
        :style="{ transform: `translateX(${translateX}px)` }"
      >
        <div
          class="topic-content"
          v-for="(topic, index) in educationData"
          :key="index"
          :style="{ width: containerWidth + 'px' }"
        >
          <h3>{{ topic.title }}</h3>
          <p>{{ topic.subtitle }}</p>

          <div v-if="topic.image_link" class="image-container">
            <img :src="topic.image_link" alt="Görsel" />
          </div>

          <div class="text-data">
            <p>{{ topic.text_data }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="navigation-icons">
      <img
        v-if="currentIndex > 0"
        src="@/assets/icon/education/previous.svg"
        alt="Önceki"
        class="nav-icon"
        @click="previousTopic"
      />
      <img
        v-if="currentIndex < educationData.length - 1"
        src="@/assets/icon/education/next.svg"
        alt="Sonraki"
        class="nav-icon"
        @click="nextTopic"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      education: {},
      educationData: [],
      currentIndex: 0,
      touchStartX: 0,
      touchCurrentX: 0,
      translateX: 0,
      isDragging: false,
      containerWidth: 0,
      gapSize: 20,
      slideWidth: 0,
    };
  },
  computed: {
    currentTopic() {
      return this.educationData[this.currentIndex] || {};
    },
  },
  methods: {
    async fetchEducationDetails() {
      try {
        const response = await axios.get(
          `/auth/educations/${this.$route.params.educationId}`
        );
 
        this.education = response.data.education;
        this.educationData = response.data.education.educationData || [];

        this.$nextTick(() => {
          this.updateContainerWidth();
          window.addEventListener('resize', this.updateContainerWidth);
        });
      } catch (error) {
        console.error('Eğitim detayları alınırken hata oluştu:', error);
      }
    },
    selectTopic(index) {
      this.currentIndex = index;
      this.translateX = -this.currentIndex * this.slideWidth;
    },
    previousTopic() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.translateX = -this.currentIndex * this.slideWidth;
      }
    },
    nextTopic() {
      if (this.currentIndex < this.educationData.length - 1) {
        this.currentIndex++;
        this.translateX = -this.currentIndex * this.slideWidth;
      }
    },
    handleTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.isDragging = true;
    },
    handleTouchMove(event) {
      if (this.isDragging) {
        this.touchCurrentX = event.touches[0].clientX;
        const deltaX = this.touchCurrentX - this.touchStartX;

        let translate = deltaX - this.currentIndex * this.slideWidth;

        if (
          (this.currentIndex === 0 && deltaX > 0) ||
          (this.currentIndex === this.educationData.length - 1 && deltaX < 0)
        ) {
          translate /= 4;
        }

        this.translateX = translate;
      }
    },
    handleTouchEnd() {
      if (this.isDragging) {
        this.isDragging = false;
        const deltaX = this.touchCurrentX - this.touchStartX;
        const threshold = this.slideWidth / 2;

        if (
          deltaX < -threshold &&
          this.currentIndex < this.educationData.length - 1
        ) {
          this.animateSlide(-this.slideWidth * (this.currentIndex + 1), () => {
            this.currentIndex++;
            this.translateX = -this.currentIndex * this.slideWidth;
          });
        } else if (deltaX > threshold && this.currentIndex > 0) {
          this.animateSlide(-this.slideWidth * (this.currentIndex - 1), () => {
            this.currentIndex--;
            this.translateX = -this.currentIndex * this.slideWidth;
          });
        } else {
          this.animateSlide(-this.currentIndex * this.slideWidth);
        }
      }
    },
    animateSlide(targetTranslateX, callback) {
      const vm = this;
      const duration = 100;
      const startTranslateX = this.translateX;
      const startTime = performance.now();

      function animate(time) {
        const elapsed = time - startTime;
        const progress = Math.min(elapsed / duration, 1);
        const easeProgress = vm.easeOutCubic(progress);
        vm.translateX =
          startTranslateX +
          (targetTranslateX - startTranslateX) * easeProgress;
        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          vm.translateX = targetTranslateX;
          if (callback) callback();
        }
      }
      requestAnimationFrame(animate);
    },
    easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    },
    updateContainerWidth() {
      if (this.$refs.swipeContainer) {
        this.containerWidth = this.$refs.swipeContainer.offsetWidth;
        this.slideWidth = this.containerWidth + this.gapSize;
        this.translateX = -this.currentIndex * this.slideWidth;
      }
    },
  },
  mounted() {
    this.fetchEducationDetails();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateContainerWidth);
  },
};
</script>


<style scoped>
.education-detail {
  color: white;
  padding: 20px;
  margin-top: 10em;
  max-width: 1360px;
  position: relative;
}
.education-name {
  font-size: 2em;
  font-weight: 200;
}
.konular {
  color: #6b6b6b;
}
.serial-no-list {
  display: flex;
  gap: 0.5em;
  margin-bottom: 20px;
  overflow-x: auto; 
  scrollbar-width: thin; 
  scrollbar-color: #065959 transparent; 
  width: 100%;
}

.serial-no-list::-webkit-scrollbar {
  height: 8px; 
}

.serial-no-list::-webkit-scrollbar-thumb {
  background-color: #065959; 
  border-radius: 10px; 
}

.serial-no-list::-webkit-scrollbar-track {
  background-color: transparent; 
} 
.serial-no-box {
  min-width: 4em;
  height: 4em;
  border: 1px solid #2d2d2d;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected {
  border-color: #065959;
  color: #065959;
}

.swipe-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  touch-action: pan-y;
}

.topics-wrapper {
  display: flex;
  transition: transform 0.3s ease;
  will-change: transform;
  gap: 20px;  
}

.topic-content {
  flex-shrink: 0;
  width: 100%; 
  padding: 20px 0;
  box-sizing: border-box;
}

.image-container img {
  width: 450px;
  height: auto;
  margin: 10px auto;
  display: block;
}

.text-data {
  margin-top: 20px;
  text-align: justify;
}
 
.navigation-icons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.nav-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
} 
@media (max-width: 728px) {
  .image-container img {
     width: 100%;
     height: auto;
     margin: 10px auto;
     display: block;
   }
} 
</style>
