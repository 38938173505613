<template>
  <div>
    <div class="sub-category-container">
      <div class="slider" @touchstart="handleTouchStart" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
        <button
          class="slider-button prev"
          @click="prevSlide"
          v-if="currentIndex > 0"
        >
          <img src="@/assets/icon/education/previous.svg" alt="Previous" />
        </button>

        <div class="slider-window">
          <div
            class="slider-track"
            :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
          >
            <div
              class="slider-item"
              v-for="(category, index) in minorCategories"
              :key="index"
            >
              <video
                v-if="category.main_video"
                class="category-video"
                :src="category.main_video"
                autoplay
                loop
                muted
                playsinline
              ></video>
              <img 
                src="@/assets/icon/education/down.svg" 
                class="down-icon" 
                alt="Info" 
                @click="scrollDown"
              />
            </div>
          </div>
        </div>
        <button
          class="slider-button next"
          @click="nextSlide"
          v-if="currentIndex < minorCategories.length - 1"
        >
          <img src="@/assets/icon/education/next.svg" alt="Next" />
        </button>
      </div>
    </div>

    <SelectedDetail
      v-if="minorCategories[currentIndex]"
      :minorCategoryId="minorCategories[currentIndex].id"
    />
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import axios from "axios";
import SelectedDetail from "./SelectedDetail.vue";

export default {
  name: "SubCategoryVideo",
  components: { SelectedDetail },
  props: {
    selectedCategory: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const minorCategories = ref([]);
    const currentIndex = ref(0);
    let touchStartX = 0;

    const fetchMinorCategories = async () => {
      try {
        if (!props.selectedCategory) return;

        const response = await axios.get(
          `/auth/minor-categories/by-sub-category/${props.selectedCategory.name}`
        );
        minorCategories.value = response.data.minorCategories || [];
        currentIndex.value = 0;
      } catch (error) {
        console.error("Minor kategoriler yüklenirken hata:", error);
      }
    };

    const prevSlide = () => {
      if (currentIndex.value > 0) {
        currentIndex.value -= 1;
      }
    };

    const nextSlide = () => {
      if (currentIndex.value < minorCategories.value.length - 1) {
        currentIndex.value += 1;
      }
    };

    const handleTouchStart = (event) => {
      touchStartX = event.touches[0].clientX;
    };

    const handleTouchMove = (event) => {
      const touchEndX = event.touches[0].clientX;
      const deltaX = touchEndX - touchStartX;

      if (Math.abs(deltaX) > 50) {
        if (deltaX > 0) {
          prevSlide();
        } else {
          nextSlide();
        }
        touchStartX = touchEndX;
      }
    };

    const handleTouchEnd = () => {};

    const scrollDown = () => {
      window.scrollBy({
        top: window.innerHeight / 2,
        behavior: "smooth",
      });
    };

    watch(
      () => props.selectedCategory,
      () => {
        fetchMinorCategories();
      },
      { immediate: true }
    );

    onMounted(() => {
      fetchMinorCategories();
    });

    return {
      minorCategories,
      currentIndex,
      prevSlide,
      nextSlide,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      scrollDown,
    };
  },
};
</script>

<style scoped>
.sub-category-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider {
  position: relative;
  width: 60%;
  display: flex;
  align-items: center;
}

.slider-window {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding-bottom: 5em;
}

.slider-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
}

.slider-item {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.category-video {
  max-width: 100%;
  max-height: 700px;
  border-radius: 8px;
}

.category-name {
  color: #fff;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}

.slider-button {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 10px;
}

.slider-button img {
  width: 5em;
  transition: transform 0.3s ease-in-out;
}

.slider-button img:hover {
  transform: scale(1.2);
}

.down-icon {
  width: 7em;
  margin-top: -5em;
  animation: bounce 2s infinite;
  display: inline-block;
  opacity: 0.4;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(12px);
  }
}

.slider-button.prev {
  left: -50px;
}

.slider-button.next {
  right: -50px;
}
</style>
