<template>
  <div :class="['header', { 'sticky': isSticky }]"> 
      <img src="../assets/noah-logo.svg" alt="Logo" class="logo" @click="goHome" />

  </div>
</template>

<script>
export default {
  name: 'MainHeader',
  data() {
    return {
      showMenu: false,
      isSticky: false,
    }
  },
  methods: {
    goHome() {
      this.$router.push('/');
    },
    navigateTo(page) {
      this.$router.push(`/${page}`);
      this.showMenu = false;
    },
    handleScroll() {
      this.isSticky = window.scrollY > 50;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
.header {  
  padding-top: 60px;
  display: flex;
  justify-content: space-between;  
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: background-color 0.3s, padding 0.3s;
  z-index: 1000;
}

.header.sticky {
  background-color: rgba(0, 0, 0, 0);
  padding: 10px;
}

.logo {
  max-width: 140px; 
  margin-left: 20px; 
  cursor: pointer;  
  z-index: 2;
  transition: max-width 0.3s;
  opacity: 0.8;
}  

.header.sticky .logo {
  max-width: 100px;
}

.menu-icon {
  max-width: 50px; 
  margin-right: 20px; 
  cursor: pointer;
  transform: scale(1); 
  transition: transform 0.3s ease, max-width 0.3s; 
  z-index: 2;
}

.header.sticky .menu-icon {
  max-width: 30px;
}

.menu-icon:hover {
  transform: scale(1.3);  
}

.dropdown-menu {
  position: absolute;
  top: 80px;
  right: 20px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
  width: 200px;
  padding: 10px 0; 
  overflow: hidden;
}

.dropdown-item {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s ease; 
}

.dropdown-item:hover {
  color: #f0f0f0;
  transform: scale(1.2); 
}

.item-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

@media (max-width: 600px) {
  .header { 
    padding: 5px;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  .header.sticky {
    padding: 5px;
    padding-top: 10px;
  }
  .logo {
    max-width: 130px; 
    opacity: 0.8;
  }
  .header.sticky .logo {
    max-width: 90px; 
  }
  .menu-icon {
    max-width: 30px; 
    margin-right: 20px; 
    cursor: pointer;
  }
  .header.sticky .menu-icon {
    max-width: 20px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
