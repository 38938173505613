import axios from 'axios';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/global-dark.css';
import './assets/animations.css';

// Axios base URL ayarı
axios.defaults.baseURL = 'https://noahpmu.com/api';
 
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const app = createApp(App);

app.use(router);

app.mount('#app');
