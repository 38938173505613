<template>
  <div class="main-div">
    <div class="video-background"> 
      <video
        v-if="!isMobile"
        autoplay
        muted 
        playsinline
      >
        <source src="@/assets/video/bg.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video> 
      <video
        v-else
        autoplay
        muted 
        playsinline
      >
        <source src="@/assets/video/bglow-mobile.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
       
      <div class="overlay-image" v-if="isMobile"></div>
    </div> 
    <div class="content">
      <MainPageNavigation />
    </div>
  </div>
</template>


<script>
import MainPageNavigation from './MainPageNavigation.vue'; 

export default {
  components: {
    MainPageNavigation 
  },
  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  },
  methods: {
    checkIfMobile() {
      this.isMobile = window.innerWidth <= 768;  
    },
  },
};
</script>

<style scoped>
.main-div {
  margin: 0;
  padding: 0;
  position: relative;
}

.video-background {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.5;
}

.content {
  margin-top: -250px;  
  position: relative;
  z-index: 2;
}
 
.overlay-image {
  display: none;
}
@media (max-width: 768px) {
  .video-background video { 
    margin: -75px 0 -120px 0;
    height: 80vh;
    object-fit: fill;
    min-width: 100%;
  }
  .content { 
    margin-top: -350px;
  }
   
  .overlay-image {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('@/assets/images/overlay/dot-gradient-gr.webp');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;  
    opacity: 0.5;
  }
}
  
</style>
